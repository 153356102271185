import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow, Ally } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Link from "next/link";
import { campaignSliderNft } from "../../services/landing";
import { useEffect, useState } from "react";
import CustomImage from "../CustomImage";
import FeatureComLoading from "./Feature_com_loading";
import { getAllCampaigns } from "../../services/campaign";
export const baseURL = `${process.env.BASE_URL}/api`;

const tempData = {
  success: true,
  campaignNames: [
    {
      impactIds: ["63c42a6b4df378abfe55cd84"],
      brandId: "63c3e85f4df378abfe55ccd9",
      causeId: "",

      impactInitiativeName: "Positive Vibes",
      camp: [
        "public/uploads/identityVerifications/banner_1674828850697_653000_Positive-Vibes-Banner.jpg",
      ],
      nft: [
        {
          owner: {
            ownerId: "6441230902fb66af1e76feea",
            walletAddress: "0xbbc4414bf8b289c10fcd2a84b6c2fd06f5cda6b9",
            username: "diogenes",
            avatarUrl: "",
          },
          creator: {
            creatorId: "63c3e0964df378abfe55cc94",
            walletAddress: "0xa828f985f8c301af02165a5b09a6008edeb058c7",
            username: "aunuaglobal",
            avatarUrl: "",
          },
          _id: "63c7dd351e9a0a0990ad7f2a",
          isSold: true,
          buyerId: "6441230902fb66af1e76feea",
          isMinted: false,
          viewsCount: 116,
          favouritesCount: 1,
          isListed: false,
          sessionId:
            "cs_live_a1ZbLqC76Asuw28NQkN7gfYxhF484BXRvLndJMfBEyhYWK2bEpEnkmAsbp",
          paymentStatus: "paid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "My World 1",
          description:
            "This is a digital reworked version of a painting by Sophia, aged 15 and one of the earliest supporters of AUNUA. By buying this, you support the movement but also gain access to a special Metaverse concert for youth. \n",
          imageUrl:
            "public/creators/assets/nft_1674042677252_760112_IMG_8550.JPG",
          nftCategory: "Artwork",
          impactId: "63c42a6b4df378abfe55cd84",
          impactInitiativeName: "Positive Vibes",
          impactCategory: "Children",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-01-18T11:51:17.731Z",
          updatedAt: "2023-12-17T10:57:13.382Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63c3e0964df378abfe55cc94",
            walletAddress: "0xa828f985f8c301af02165a5b09a6008edeb058c7",
            username: "aunuaglobal",
            avatarUrl: "",
          },
          _id: "63c7deef1e9a0a0990ad7fa6",
          isSold: false,
          buyerId: "645431fc4d7c29c3de585de1",
          isMinted: false,
          viewsCount: 114,
          favouritesCount: 0,
          isListed: true,
          sessionId:
            "cs_live_a1XVbaWcVGkSLYY0X5VUus9ewxvpTq68mhJuJ7SS9Z2H0nq7u3c9TT92ZD",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "My World 2",
          description:
            "This is a digital reworked version of a painting by Sophia, aged 15 and one of the earliest supporters of AUNUA. By buying this, you support the movement but also gain access to a special Metaverse concert for youth. \n",
          imageUrl:
            "public/creators/assets/nft_1674043119491_508648_IMG_8551.jpg",
          nftCategory: "Artwork",
          impactId: "63c42a6b4df378abfe55cd84",
          impactInitiativeName: "Positive Vibes",
          impactCategory: "Children",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-01-18T11:58:39.962Z",
          updatedAt: "2024-01-21T08:26:50.503Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63c3e0964df378abfe55cc94",
            walletAddress: "0xa828f985f8c301af02165a5b09a6008edeb058c7",
            username: "aunuaglobal",
            avatarUrl: "",
          },
          _id: "63c7df6e1e9a0a0990ad7fd6",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 62,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "My World 3",
          description:
            "This is a digital reworked version of a painting by Sophia, aged 15 and one of the earliest supporters of AUNUA. By buying this, you support the movement but also gain access to a special Metaverse concert for youth. \r\n",
          imageUrl:
            "public/creators/assets/nft_1674043246508_930662_IMG_8552.jpg",
          nftCategory: "Artwork",
          impactId: "63c42a6b4df378abfe55cd84",
          impactInitiativeName: "Positive Vibes",
          impactCategory: "Children",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-01-18T12:00:46.969Z",
          updatedAt: "2024-01-18T12:16:04.849Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63c3e0964df378abfe55cc94",
            walletAddress: "0xa828f985f8c301af02165a5b09a6008edeb058c7",
            username: "aunuaglobal",
            avatarUrl: "",
          },
          _id: "63c7e0991e9a0a0990ad8003",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 29,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "My World 4",
          description:
            "This is a digital reworked version of a painting by Sophia, aged 15 and one of the earliest supporters of AUNUA. By buying this, you support the movement but also gain access to a special Metaverse concert for youth. \r\n",
          imageUrl:
            "public/creators/assets/nft_1674043545318_544213_IMG_8553.jpg",
          nftCategory: "Artwork",
          impactId: "63c42a6b4df378abfe55cd84",
          impactInitiativeName: "Positive Vibes",
          impactCategory: "Children",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-01-18T12:05:45.844Z",
          updatedAt: "2023-12-15T19:36:21.402Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
      ],
    },

    {
      impactIds: ["643179e5d001d4fd2f5e63e5"],
      brandId: "",
      causeId: "641ee6faff96c10593df779a",
      impactInitiativeName: "Road to Education ",
      camp: [
        "public/uploads/identityVerifications/banner_1680964069102_585789_1a7d6211-d174-41bd-b180-e662d2286e0a.jpg",
      ],
      nft: [
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "64189faac1877fb6b12645a3",
            walletAddress: "0xecc239a31056f35c022ef0d4ac5f12f4556ac74e",
            username: "nassimka",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxYUPwkQOZ0DNnaPQRvAJiPDJoCtW7CjX0dO89go=s96-c",
          },
          _id: "64b6f6718ca9dd5d4d8df9da",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 4,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Support for Afghan girls",
          description:
            "The work was drawn by one of our young artists Samira, who is originally from Afghanistan. The girl painted a picture having inspiration to create - displaying her talent and creative vision of this world! The painting was sent to us by Samira to support the education fundraiser for Afghan girls!\n\nEveryone who supports girls by buying an image will gain access to the Positive Vibes Festival by AUNUA Global! Find out more about that on this link: https://impactoverse.com/browse-sub-campaign/63c42a6b4df378abfe55cd84\n*buying an image you will be able to get an original artwork too. \n",
          imageUrl:
            "public/creators/assets/nft_1689712241129_382053_77a990c3-2859-4ef3-9ef8-3e77dca6b436.jpg",
          nftCategory: "Artwork",
          collectionId: "64a6abce8ca9dd5d4d8de561",
          attributes: [],
          impactId: "643179e5d001d4fd2f5e63e5",
          impactInitiativeName: "Road to Education ",
          impactCategory: "Education",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          __v: 0,
          createdAt: "2023-07-18T20:30:41.646Z",
          updatedAt: "2023-08-17T18:45:12.935Z",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "64189faac1877fb6b12645a3",
            walletAddress: "0xecc239a31056f35c022ef0d4ac5f12f4556ac74e",
            username: "nassimka",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxYUPwkQOZ0DNnaPQRvAJiPDJoCtW7CjX0dO89go=s96-c",
          },
          _id: "64b6f8b38ca9dd5d4d8dfa54",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 5,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Afghan girl",
          description:
            "A drawing of an Afghan girl in a meadow where butterflies flutter around her - the image of a girl shows us the traditional clothes of the Afghans. This is how Samira shows us her amazing works.\n\nEveryone who supports girls by buying an image will gain access to the Positive Vibes Festival by AUNUA Global! Find out more about that on this link: https://impactoverse.com/browse-sub-campaign/63c42a6b4df378abfe55cd84\n",
          imageUrl:
            "public/creators/assets/nft_1689712819390_390900_18b127da-86f2-475a-bddd-9cb1e96516d6.jpg",
          nftCategory: "Artwork",
          collectionId: "64a6abce8ca9dd5d4d8de561",
          attributes: [],
          impactId: "643179e5d001d4fd2f5e63e5",
          impactInitiativeName: "Road to Education ",
          impactCategory: "Education",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          __v: 0,
          createdAt: "2023-07-18T20:40:19.854Z",
          updatedAt: "2023-12-19T19:46:22.868Z",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "64189faac1877fb6b12645a3",
            walletAddress: "0xecc239a31056f35c022ef0d4ac5f12f4556ac74e",
            username: "nassimka",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxYUPwkQOZ0DNnaPQRvAJiPDJoCtW7CjX0dO89go=s96-c",
          },
          _id: "64b6fcac8ca9dd5d4d8dfae6",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 4,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Support for Afghan girls",
          description:
            "A woodcut representing the shape of Afghanistan on the World Map. The work of the girl Samira, whose home country is Afghanistan. The work was sent to us to help raise funds for the education of Afghan girls.\n\nEveryone who supports girls by buying an image will gain access to the Positive Vibes Festival by AUNUA Global! Find out more about that on this link: https://impactoverse.com/browse-sub-campaign/63c42a6b4df378abfe55cd84",
          imageUrl:
            "public/creators/assets/nft_1689713835680_558665_aff0313b-3906-4507-bcd1-2ad62b56730f.jpg",
          nftCategory: "Artwork",
          collectionId: "64a6abce8ca9dd5d4d8de561",
          attributes: [],
          impactId: "643179e5d001d4fd2f5e63e5",
          impactInitiativeName: "Road to Education ",
          impactCategory: "Education",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          __v: 0,
          createdAt: "2023-07-18T20:57:16.299Z",
          updatedAt: "2023-08-15T15:57:59.248Z",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "64189faac1877fb6b12645a3",
            walletAddress: "0xecc239a31056f35c022ef0d4ac5f12f4556ac74e",
            username: "nassimka",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxYUPwkQOZ0DNnaPQRvAJiPDJoCtW7CjX0dO89go=s96-c",
          },
          _id: "64de6dfcfe7672eafc2226a9",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 1,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Education is  my Right ",
          description:
            "Painting by the young artist Samira, who began her career as an artist at the age of 14:  with the encouragement of my family and friends I found that drawing gives me good feelings. After Samira found her talent, she joined us to invest in her contribution to provide Afghan girls with an online education. \n\n*Everyone who supports girls by buying an image will gain access to the Positive Vibes Festival by AUNUA Global! Find out more about that on this link: https://impactoverse.com/browse-sub-campaign/63c42a6b4df378abfe55cd84 *buying an image you will be able to get an original artwork too.",
          imageUrl:
            "public/creators/assets/nft_1692298748463_864342_36a21a34-a876-476e-a16b-34662d074251.jpg",
          nftCategory: "Artwork",
          collectionId: "64da2547fd9e859ca1767329",
          attributes: [],
          impactId: "643179e5d001d4fd2f5e63e5",
          impactInitiativeName: "Road to Education ",
          impactCategory: "Education",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          __v: 0,
          createdAt: "2023-08-17T18:59:08.954Z",
          updatedAt: "2023-10-04T19:49:12.755Z",
        },
      ],
    },
    {
      impactIds: ["63f51a90c040bbe49f9b0dba"],
      impactInitiativeName: "A World of Wonder",

      brandId: "",
      causeId: "63e004f4c040bbe49f9aff9e",
      camp: [
        "public/uploads/identityVerifications/banner_1677007504495_840448_adveturelogue(920Ã411px).png",
      ],
      nft: [
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63d6f06a7bf683b8c836c25b",
            walletAddress: "0x48cc5d942cb92f5f7f9ee6a09b78ef6696a9d431",
            username: "adventurelogue_uk",
            avatarUrl: "",
          },
          _id: "63f539e8c040bbe49f9b0e8e",
          isSold: false,
          buyerId: "63c3ba94fb29d92338cdb5f4",
          isMinted: false,
          viewsCount: 24,
          favouritesCount: 0,
          isListed: true,
          sessionId:
            "cs_live_a12iYYTuaINaLdQEi6WbnmEJVYBYGk3bXDMIgNU4JhO6UJUzm0qiLni1n5",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 20,
          itemName: "Mt Eden ",
          description: "Memories of Auckland, New Zealand",
          imageUrl:
            "public/creators/assets/nft_1677015528418_814884_arpp5a9adTxVSMZcCg7a.jpg",
          nftCategory: "Artwork",
          impactId: "63f51a90c040bbe49f9b0dba",
          impactInitiativeName: "A World of Wonder",
          impactCategory: "International Aid",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-02-21T21:38:48.886Z",
          updatedAt: "2023-12-22T18:11:12.029Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63d6f06a7bf683b8c836c25b",
            walletAddress: "0x48cc5d942cb92f5f7f9ee6a09b78ef6696a9d431",
            username: "adventurelogue_uk",
            avatarUrl: "",
          },
          collectionId: "",
          _id: "63f53bb6c040bbe49f9b0ead",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 10,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 20,
          itemName: "Ohakune ",
          description: "Beautiful New Zealand",
          imageUrl:
            "public/creators/assets/nft_1677015990146_745326_Q93mU8ZH1gryFmTTK4Xn.jpg",
          nftCategory: "Artwork",
          impactId: "63f51a90c040bbe49f9b0dba",
          impactInitiativeName: "A World of Wonder",
          impactCategory: "International Aid",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-02-21T21:46:30.618Z",
          updatedAt: "2023-04-03T11:38:59.732Z",
          __v: 0,
          attributes: [],
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63d6f06a7bf683b8c836c25b",
            walletAddress: "0x48cc5d942cb92f5f7f9ee6a09b78ef6696a9d431",
            username: "adventurelogue_uk",
            avatarUrl: "",
          },
          _id: "63fe42f4e4a1d8b382574130",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 9,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 20,
          itemName: "Memories of Rome",
          description: "St. Peter's Square",
          imageUrl:
            "public/creators/assets/nft_1677607667837_820246_6d19e093-c636-46de-8321-3ab1e1c49ac6",
          nftCategory: "Artwork",
          impactId: "63f51a90c040bbe49f9b0dba",
          impactInitiativeName: "A World of Wonder",
          impactCategory: "International Aid",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-02-28T18:07:48.301Z",
          updatedAt: "2023-12-13T23:11:24.944Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63d6f06a7bf683b8c836c25b",
            walletAddress: "0x48cc5d942cb92f5f7f9ee6a09b78ef6696a9d431",
            username: "adventurelogue_uk",
            avatarUrl: "",
          },
          _id: "63fe713ee4a1d8b382574243",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 3,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 20,
          itemName: "Memories of the Algarve",
          description: "View of the ocean through rocks.",
          imageUrl:
            "public/creators/assets/nft_1677619518219_498605_6b8bb023-4f1f-4cee-a1ef-1cb0254b8ca2",
          nftCategory: "Artwork",
          impactId: "63f51a90c040bbe49f9b0dba",
          impactInitiativeName: "A World of Wonder",
          impactCategory: "International Aid",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-02-28T21:25:18.691Z",
          updatedAt: "2023-12-15T13:06:49.208Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
      ],
    },
    {
      impactIds: ["6418152cc1877fb6b1264437"],
      impactInitiativeName: "SaferWays Kids",
      brandId: "",
      causeId: "63e0053fc040bbe49f9affa8",
      camp: [
        "public/uploads/identityVerifications/banner_1679263703208_968836_f4b94d81-b49b-47f6-9fe9-06cb90155c28.png",
      ],
      nft: [
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63d6eed57bf683b8c836c24a",
            walletAddress: "0x6cadba4d540269f192cd7c1420f4130663417e07",
            username: "bearmartialarts",
            avatarUrl: "",
          },
          _id: "641a2b65ff96c10593df61a4",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 19,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 99,
          itemName: "1/16 Limited Edition Kitana Gi",
          description:
            "Limited Edition Bear Martial Arts mascot wearing a Gi and wielding a kitana. Purchase will include a t-shirt (contact via bearmartialarts.com) and entry to the Positive Vibes Festival in August (details on positivevibesglobal.com).",
          imageUrl:
            "public/creators/assets/nft_1679436645100_428803_fcef180f-adca-4b09-b164-16f43ff9fcc5.jpg",
          nftCategory: "Artwork",
          impactId: "6418152cc1877fb6b1264437",
          impactInitiativeName: "SaferWays Kids",
          impactCategory: "Children",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-21T22:10:45.653Z",
          updatedAt: "2023-12-20T09:20:29.378Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63d6eed57bf683b8c836c24a",
            walletAddress: "0x6cadba4d540269f192cd7c1420f4130663417e07",
            username: "bearmartialarts",
            avatarUrl: "",
          },
          _id: "641a2c07ff96c10593df61ce",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 7,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 99,
          itemName: "2/16 Limited Edition Hands Gi",
          description:
            "Limited edition Bear Martial Arts mascot wearing a Gi. Purchase will also include a Bear Martial Arts t-shirt, (contact via bearmartialarts.com) and entry to the Positive Vibes Festival in August, (see positivevibesglobal.com). ",
          imageUrl:
            "public/creators/assets/nft_1679436806592_922374_0e14af2a-c633-48e5-8579-bd6f27f67c25.jpg",
          nftCategory: "Artwork",
          impactId: "6418152cc1877fb6b1264437",
          impactInitiativeName: "SaferWays Kids",
          impactCategory: "Children",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-21T22:13:27.091Z",
          updatedAt: "2024-01-07T08:22:34.463Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63d6eed57bf683b8c836c24a",
            walletAddress: "0x6cadba4d540269f192cd7c1420f4130663417e07",
            username: "bearmartialarts",
            avatarUrl: "",
          },
          _id: "641a2d2aff96c10593df61ec",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 8,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 99,
          itemName: "3/16 Limited Edition Nunchaku Gi",
          description:
            "Limited edition Bear Martial Arts mascot wearing a Gi and wielding nunchaku. Purchase includes a Bear Martial Arts t-shirt (contact via bearmartialarts.com), and entry to the Positive Vibes Festival in August, (see positivevibesglobal.com).",
          imageUrl:
            "public/creators/assets/nft_1679437098406_192496_181f5f5f-e4e2-4ca4-b9e7-df8d87957dda.jpg",
          nftCategory: "Artwork",
          impactId: "6418152cc1877fb6b1264437",
          impactInitiativeName: "SaferWays Kids",
          impactCategory: "Children",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-21T22:18:18.890Z",
          updatedAt: "2023-12-13T23:15:54.644Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
      ],
    },
    {
      impactIds: ["6416fb5cc1877fb6b1264098"],
      impactInitiativeName: "Esther's Orphanage ",
      brandId: "63c3e85f4df378abfe55ccd9",
      causeId: "",
      camp: [
        "public/uploads/identityVerifications/banner_1679308253392_667901_b8890f59-3a62-4675-8926-9a8d4f5d47f1.jpg",
      ],
      nft: [
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63c3e0964df378abfe55cc94",
            walletAddress: "0xa828f985f8c301af02165a5b09a6008edeb058c7",
            username: "aunuaglobal",
            avatarUrl: "",
          },
          _id: "641ad875ff96c10593df639f",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 9,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Basket of Apples - Original",
          description:
            "Drawn by Namaara Felix, aged 10. By purchasing this Impact DOT you not only support the activity of the orphanage but also gain entry to The Positive Vibes Festival in August. For more information, check out positivevibesglobal.com. ",
          imageUrl:
            "public/creators/assets/nft_1679480949096_173922_282f5ffc-c757-41a2-ae7d-136395e62aa4.jpg",
          nftCategory: "Artwork",
          impactId: "6416fb5cc1877fb6b1264098",
          impactInitiativeName: "Esther's Orphanage ",
          impactCategory: "Health",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-22T10:29:09.576Z",
          updatedAt: "2023-12-19T23:18:06.207Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63c3e0964df378abfe55cc94",
            walletAddress: "0xa828f985f8c301af02165a5b09a6008edeb058c7",
            username: "aunuaglobal",
            avatarUrl: "",
          },
          _id: "641ad920ff96c10593df63be",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 9,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Basket of Apples - Effects",
          description:
            "To increase the opportunity to earn for our project we used 10 year old Namaara Felix's drawing and added some effects to it. By purchasing this Impact DOT you not only support the orphanage but also gain entry to The Positive Vibes Festival in August. For more information, check out positivevibesglobal.com. ",
          imageUrl:
            "public/creators/assets/nft_1679481119885_884758_3c1cf87a-ea07-4c83-ac15-31043c138194.jpg",
          nftCategory: "Artwork",
          impactId: "6416fb5cc1877fb6b1264098",
          impactInitiativeName: "Esther's Orphanage ",
          impactCategory: "Health",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-22T10:32:00.365Z",
          updatedAt: "2023-12-13T21:12:32.780Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63c3e0964df378abfe55cc94",
            walletAddress: "0xa828f985f8c301af02165a5b09a6008edeb058c7",
            username: "aunuaglobal",
            avatarUrl: "",
          },
          collectionId: "",
          _id: "641ada94ff96c10593df63fa",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 0,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Blue Mug - Original",
          description:
            "Another drawing by Namaara Felix, aged 10. By purchasing this Impact DOT, you not only support the orphanage but you also gain entry to The Positive Vibes Festival in August. For more information, check out positivevibesglobal.com. ",
          imageUrl:
            "public/creators/assets/nft_1679481492374_888286_03bbeb0b-b77a-4360-802c-ab004311ffc0.jpg",
          nftCategory: "Artwork",
          impactId: "6416fb5cc1877fb6b1264098",
          impactInitiativeName: "Esther's Orphanage ",
          impactCategory: "Health",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-22T10:38:12.841Z",
          updatedAt: "2023-03-22T10:38:12.841Z",
          __v: 0,
          attributes: [],
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "63c3e0964df378abfe55cc94",
            walletAddress: "0xa828f985f8c301af02165a5b09a6008edeb058c7",
            username: "aunuaglobal",
            avatarUrl: "",
          },
          collectionId: "",
          _id: "641adb17ff96c10593df6420",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 2,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Blue Cup - Effects",
          description:
            "We took a drawing by Naamara, age 10, and added some effects to it to increase the potential of earning for the orphanage. By purchasing this Impact DOT, you not only support the orphanage but you also gain entry to The Positive Vibes Festival in August. For more information, check out positivevibesglobal.com. ",
          imageUrl:
            "public/creators/assets/nft_1679481622545_724546_60da8d83-ca84-49aa-a481-2d6a02cefd2a.jpg",
          nftCategory: "Artwork",
          impactId: "6416fb5cc1877fb6b1264098",
          impactInitiativeName: "Esther's Orphanage ",
          impactCategory: "Health",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-22T10:40:23.017Z",
          updatedAt: "2023-03-23T01:11:01.732Z",
          __v: 0,
          attributes: [],
        },
      ],
    },
    {
      impactIds: ["64183c3bc1877fb6b12644ab"],
      impactInitiativeName: "She Hygiene",
      brandId: "",
      causeId: "640b37dfc1877fb6b1263641",
      camp: [
        "public/uploads/identityVerifications/banner_1679295021370_782963_5bf27f5d-317b-4bc6-a809-27ce66fab31a.jpg",
      ],
      nft: [
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "641023bdc1877fb6b126390a",
            walletAddress: "0xd394325a920b616b6988b55fd25a981925535c68",
            username: "werisetogetherfoundation",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxbjmwylwCF2UUT14BoANVjHnTREUBpByCqy3o1K=s96-c",
          },
          _id: "641b05e9ff96c10593df66c3",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 18,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 25,
          itemName: "Menstruating Lady potrait",
          description:
            "This is an abstract imagery of a woman going through her periods. It shows the mood swings and cramps every woman has to go through every month. This is a depiction of the overall picture of menstruation. ",
          imageUrl:
            "public/creators/assets/nft_1679492584759_593846_b121c428-7a80-4496-998c-68ec8ce150ae.jpg",
          nftCategory: "Artwork",
          impactId: "64183c3bc1877fb6b12644ab",
          impactInitiativeName: "She Hygiene",
          impactCategory: "Health",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-22T13:43:05.232Z",
          updatedAt: "2023-12-16T21:12:27.340Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "641023bdc1877fb6b126390a",
            walletAddress: "0xd394325a920b616b6988b55fd25a981925535c68",
            username: "werisetogetherfoundation",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxbjmwylwCF2UUT14BoANVjHnTREUBpByCqy3o1K=s96-c",
          },
          _id: "641b0700ff96c10593df66e1",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 1,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Periods of Earth",
          description:
            "This is an imagery of a scenario where earth is menstruating. This can be said to take inspiration from the Raja Festival. It is believed that the mother Goddess Earth or the divine wife of Lord Vishnu undergoes menstruation during the first three days. The fourth day is called VasumatiSnana, or ceremonial bath of Bhudevi.",
          imageUrl:
            "public/creators/assets/nft_1679492863982_487755_2c5c7e17-145c-4479-9ff5-dd58614df66d.jpg",
          nftCategory: "Artwork",
          impactId: "64183c3bc1877fb6b12644ab",
          impactInitiativeName: "She Hygiene",
          impactCategory: "Health",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-22T13:47:44.458Z",
          updatedAt: "2023-05-03T13:49:30.273Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "641023bdc1877fb6b126390a",
            walletAddress: "0xd394325a920b616b6988b55fd25a981925535c68",
            username: "werisetogetherfoundation",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxbjmwylwCF2UUT14BoANVjHnTREUBpByCqy3o1K=s96-c",
          },
          _id: "641b079fff96c10593df6700",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 4,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 15,
          itemName: "Red River",
          description:
            "This signifies the river turning red due to menstruation. This is inspired by the menstruating godess- Kamakhya Devi. Kamakhya Devi is famous as the bleeding Goddess. The mythical womb and vagina of Shakti are supposedlyinstalled in the 'Garvagriha' or sanctum of the temple. In the month of Ashaad(June), the Goddess bleeds or menstruates. At this time, the Brahmaputra rivernear Kamakhya turns red. The temple then remains closed for 3 days, and holy wateris distributed among the devotees of Kamakhya Devi.",
          imageUrl:
            "public/creators/assets/nft_1679493023386_869528_8bb7e67d-3598-445a-83bc-b43118a4d9cd.jpg",
          nftCategory: "Artwork",
          impactId: "64183c3bc1877fb6b12644ab",
          impactInitiativeName: "She Hygiene",
          impactCategory: "Health",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-22T13:50:23.854Z",
          updatedAt: "2023-12-11T18:28:12.620Z",
          __v: 0,
          attributes: [],
          collectionId: "",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "641023bdc1877fb6b126390a",
            walletAddress: "0xd394325a920b616b6988b55fd25a981925535c68",
            username: "werisetogetherfoundation",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxbjmwylwCF2UUT14BoANVjHnTREUBpByCqy3o1K=s96-c",
          },
          collectionId: "",
          _id: "641b080bff96c10593df671e",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 0,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 20,
          itemName: "Art work on Menstruation",
          description:
            "This is an artwork on menstruation. This is an artistic depiction of what menstruators feel during their periods.",
          imageUrl:
            "public/creators/assets/nft_1679493131442_939253_201d5394-b721-4540-8c20-519c407af242.jpg",
          nftCategory: "Artwork",
          impactId: "64183c3bc1877fb6b12644ab",
          impactInitiativeName: "She Hygiene",
          impactCategory: "Health",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-03-22T13:52:11.903Z",
          updatedAt: "2023-03-22T13:52:11.903Z",
          __v: 0,
          attributes: [],
        },
      ],
    },
    {
      impactIds: ["648114bd2e4e15627b786187"],
      impactInitiativeName: "Action for Equality",
      brandId: "",
      causeId: "646367692079da1f93a1c2d6",
      camp: [
        "public/uploads/identityVerifications/banner_1686181053140_688039_4bb666a5-5074-4673-a355-0dc82a5e496e.jpg",
      ],
      nft: [
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "64189faac1877fb6b12645a3",
            walletAddress: "0xecc239a31056f35c022ef0d4ac5f12f4556ac74e",
            username: "nassimka",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxYUPwkQOZ0DNnaPQRvAJiPDJoCtW7CjX0dO89go=s96-c",
          },
          _id: "64d099753d69346d9a2f17c2",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 52,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 3000,
          itemName: "Support for Afghan girls",
          description:
            "What do you see on this picture? It's like it's talking to you, isn't it? After looking at the picture for a minute, you can see Afghanistan through the eyes of an Afghan girl - little Samira. Everyone who supports girls by buying an image will gain access to the Positive Vibes Festival by AUNUA Global! Find out more about that on this link: https://impactoverse.com/browse-sub-campaign/63c42a6b4df378abfe55cd84\n*buying an image you will be able to get an original artwork too. ",
          imageUrl:
            "public/creators/assets/nft_1691392372942_473349_e3ea709b-2914-48b3-a4b2-6be26583f2fb.jpg",
          nftCategory: "Artwork",
          collectionId: "64a6abce8ca9dd5d4d8de561",
          attributes: [],
          impactId: "648114bd2e4e15627b786187",
          impactInitiativeName: "Action for Equality",
          impactCategory: "Education",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          __v: 0,
          createdAt: "2023-08-07T07:12:53.505Z",
          updatedAt: "2023-12-29T17:44:39.825Z",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "64189faac1877fb6b12645a3",
            walletAddress: "0xecc239a31056f35c022ef0d4ac5f12f4556ac74e",
            username: "nassimka",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxYUPwkQOZ0DNnaPQRvAJiPDJoCtW7CjX0dO89go=s96-c",
          },
          _id: "64d3a5aafd9e859ca1765f03",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 32,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 3000,
          itemName: "Education is my Right ",
          description:
            " In the picture you can see drawing of an Afghan girl Samira, she painted with motives to show the truth of what is happening in Afghanistan right now. After all, Afghanistan is the only country where girls are forbidden to study without any reasonable explananation. That is why we are here! Everyone who supports girls by buying an image will gain access to the Positive Vibes Festival by AUNUA Global! Find out more about that on this link: https://impactoverse.com/browse-sub-campaign/63c42a6b4df378abfe55cd84 * buying a painting you will be able to get an original artwork as well.",
          imageUrl:
            "public/creators/assets/nft_1691592105687_186042_b449cf24-b5f5-47f8-a94a-4b3912e9cc6d.jpg",
          nftCategory: "Artwork",
          collectionId: "64d3a596fd9e859ca1765ef9",
          attributes: [],
          impactId: "648114bd2e4e15627b786187",
          impactInitiativeName: "Action for Equality",
          impactCategory: "Education",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          __v: 0,
          createdAt: "2023-08-09T14:41:46.291Z",
          updatedAt: "2023-12-19T23:15:27.595Z",
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "64189faac1877fb6b12645a3",
            walletAddress: "0xecc239a31056f35c022ef0d4ac5f12f4556ac74e",
            username: "nassimka",
            avatarUrl:
              "https://lh3.googleusercontent.com/a/AGNmyxYUPwkQOZ0DNnaPQRvAJiPDJoCtW7CjX0dO89go=s96-c",
          },
          _id: "64d3a69bfd9e859ca1765f52",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 16,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 3000,
          itemName: "Life - is for Everyone",
          description:
            'The drawing is drawn by a young lady Samira, who is originally from Afghanistan. The girl revealed what she had depicted in the painting: “This is about a big explosion in Kabul Dasht Barchi, which killed many children and women. One of the soldiers is holding an already dead child, hoping to help him." *Everyone who supports girls by buying an image will gain access to the Positive Vibes Festival by AUNUA Global! Find out more about that on this link: https://impactoverse.com/browse-sub-campaign/63c42a6b4df378abfe55cd84 *buying an image you will be able to get an original artwork too.',
          imageUrl:
            "public/creators/assets/nft_1691592346671_269369_7fece539-9dc1-4a7f-b691-2c273a648907.jpg",
          nftCategory: "Artwork",
          collectionId: "64d3a64afd9e859ca1765f3e",
          attributes: [],
          impactId: "648114bd2e4e15627b786187",
          impactInitiativeName: "Action for Equality",
          impactCategory: "Education",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          __v: 0,
          createdAt: "2023-08-09T14:45:47.327Z",
          updatedAt: "2024-01-15T10:50:13.587Z",
        },
      ],
    },
    {
      impactIds: ["644e1ba84d7c29c3de584700"],
      impactInitiativeName: "Water for Life",
      brandId: "",
      causeId: "644d3dbb4d7c29c3de5844a7",
      camp: [
        "public/uploads/identityVerifications/banner_1682840487380_582018_3397b0b7-fbfa-49b3-8410-d739cf41a969.png",
      ],
      nft: [
        {
          owner: {
            ownerId: "648c1eee9fb13584e4aa0499",
            walletAddress: "0x02b10c831a87ca82272d0a742ff7750618756320",
            username: "shygirl",
            avatarUrl: "",
          },
          creator: {
            creatorId: "6409c469c1877fb6b1263492",
            walletAddress: "0x55de9ca7b6c410e00a4e296f38f73a837e3e2b25",
            username: "thinkocean",
            avatarUrl: "",
          },
          _id: "644e46c14d7c29c3de584822",
          isSold: true,
          buyerId: "648c1eee9fb13584e4aa0499",
          isMinted: false,
          viewsCount: 7,
          favouritesCount: 0,
          isListed: false,
          sessionId:
            "cs_live_a1qYvHm88OHneFQfZkI25z1E52bLV6RdXK7Qqq6MhJF82O8c48fOEtH8Ft",
          paymentStatus: "paid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Ocean Coral",
          description:
            "Sustaining life in and out of the oceans. Buy this image, support the protection of our water systems, and start your impact Dot portfolio. ",
          imageUrl:
            "public/creators/assets/nft_1682851521497_894861_b596a749-3973-464a-acc3-48b0ab8b7968.jpg",
          nftCategory: "Artwork",
          collectionId: "",
          attributes: [],
          impactId: "644e1ba84d7c29c3de584700",
          impactInitiativeName: "Water for Life",
          impactCategory: "Environment",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-04-30T10:45:21.989Z",
          updatedAt: "2023-12-02T08:03:32.753Z",
          __v: 0,
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "6409c469c1877fb6b1263492",
            walletAddress: "0x55de9ca7b6c410e00a4e296f38f73a837e3e2b25",
            username: "thinkocean",
            avatarUrl: "",
          },
          _id: "644e472d4d7c29c3de584841",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 3,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Floating Worlds ",
          description:
            "Fight against Floating Garbage Islands. Buy this image, support the protection of our water systems, and start your impact Dot portfolio. ",
          imageUrl:
            "public/creators/assets/nft_1682851628776_353050_bd58b175-5226-4a79-bfd9-07ad772c5024.jpg",
          nftCategory: "Artwork",
          collectionId: "",
          attributes: [],
          impactId: "644e1ba84d7c29c3de584700",
          impactInitiativeName: "Water for Life",
          impactCategory: "Environment",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-04-30T10:47:09.259Z",
          updatedAt: "2023-12-13T07:28:39.442Z",
          __v: 0,
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "6409c469c1877fb6b1263492",
            walletAddress: "0x55de9ca7b6c410e00a4e296f38f73a837e3e2b25",
            username: "thinkocean",
            avatarUrl: "",
          },
          _id: "644e47864d7c29c3de584862",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 7,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Calm",
          description:
            "The beauty of water. Buy this image, support the protection of our water systems, and start your impact Dot portfolio. ",
          imageUrl:
            "public/creators/assets/nft_1682851717625_330227_2f3d62fa-8b99-4ba2-b864-9dadfd1f0164.jpg",
          nftCategory: "Artwork",
          collectionId: "",
          attributes: [],
          impactId: "644e1ba84d7c29c3de584700",
          impactInitiativeName: "Water for Life",
          impactCategory: "Environment",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-04-30T10:48:38.201Z",
          updatedAt: "2023-12-13T23:05:59.652Z",
          __v: 0,
        },
        {
          owner: {
            ownerId: null,
            walletAddress: null,
            username: null,
            avatarUrl: null,
          },
          creator: {
            creatorId: "6409c469c1877fb6b1263492",
            walletAddress: "0x55de9ca7b6c410e00a4e296f38f73a837e3e2b25",
            username: "thinkocean",
            avatarUrl: "",
          },
          _id: "644e47bb4d7c29c3de584881",
          isSold: false,
          buyerId: "",
          isMinted: false,
          viewsCount: 7,
          favouritesCount: 0,
          isListed: true,
          sessionId: "",
          paymentStatus: "unpaid",
          saleType: "FIXED",
          priceUsd: 10,
          itemName: "Turbulence",
          description:
            "we are in turbulent times! Buy this image, support the protection of our water systems, and start your impact Dot portfolio. ",
          imageUrl:
            "public/creators/assets/nft_1682851770815_109369_9053c538-480b-49f3-a662-522fa7ce9c65.jpg",
          nftCategory: "Artwork",
          collectionId: "",
          attributes: [],
          impactId: "644e1ba84d7c29c3de584700",
          impactInitiativeName: "Water for Life",
          impactCategory: "Environment",
          percentageToImpact: 100,
          impactCampaignType: "CAMPAIGN",
          isApprovedForContribution: true,
          createdAt: "2023-04-30T10:49:31.338Z",
          updatedAt: "2023-12-13T07:55:18.236Z",
          __v: 0,
        },
      ],
    },
  ],
};
const Feature_collections_carousel = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchApi = () => {
      // getImpact DOT()
      campaignSliderNft()
        .then((res) => {
          // debugger
          let tempArray = [];
          // let temp = res?.campaignNames;
          // Hiding Temp
          let temp = tempData.campaignNames;
          temp.filter((item) => {
            // debugger
            const imageName = item.impactInitiativeName;
            const stringWithoutSpaces = imageName.replace(/\s/g, "");

            const campaignImageTempSmall1 =
              "/images/collections/campaigns/" +
              stringWithoutSpaces +
              "/" +
              stringWithoutSpaces +
              "_1" +
              ".png";
            const campaignImageTempSmall2 =
              "/images/collections/campaigns/" +
              stringWithoutSpaces +
              "/" +
              stringWithoutSpaces +
              "_2" +
              ".png";
            const campaignImageTempSmall3 =
              "/images/collections/campaigns/" +
              stringWithoutSpaces +
              "/" +
              stringWithoutSpaces +
              "_3" +
              ".png";
            const organizationImage =
              "/images/collections/campaigns/" +
              stringWithoutSpaces +
              "/" +
              stringWithoutSpaces +
              "_org" +
              ".png";
            let newobj = {
              ids: item.impactIds[0],
              campimage: item.camp,
              CampName: item.impactInitiativeName,
              nft: item.nft,
              campaignImageTemp:
                "/images/collections/campaigns/" + stringWithoutSpaces + ".png",
              campaignImageSmallTemp1: campaignImageTempSmall1,
              campaignImageSmallTemp2: campaignImageTempSmall2,
              campaignImageSmallTemp3: campaignImageTempSmall3,
              organizationImage: organizationImage,
              brandId: item.brandId,
              causeId: item.causeId,
            };
            tempArray.push(newobj);
            // // console.log(newobj.nft);
          });
          setData(tempArray);
          // setSliderData(res?.campaignNames);
          setLoading(true);
          // // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
        });
    };
    fetchApi();
  }, []);

  return (
    <>
      {loading ? (
        <>
          {" "}
          <Swiper
            breakpoints={{
              // when window width is >= 640px
              100: {
                // width: 640,
                slidesPerView: 1,
              },
              575: {
                // width: 640,
                slidesPerView: 3,
              },
              // when window width is >= 768px
              992: {
                // width: 768,
                slidesPerView: 5,
              },
            }}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={5}
            loop={true}
            coverflowEffect={{
              rotate: 30,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination, Navigation]}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            className="swiper coverflow-slider !py-5"
          >
            {data?.map((item, index) => {
              const {
                CampName,
                campimage,
                ids,
                nft,
                campaignImageTemp,
                campaignImageSmallTemp1,
                campaignImageSmallTemp2,
                campaignImageSmallTemp3,
                organizationImage,
                brandId,
                causeId,
              } = item;
              // console.log("Image of Campaign", campimage);
              // console.log("Campaign index", index + campimage);
              let hrefOrg;
              if (brandId === "63c3e85f4df378abfe55ccd9") {
                hrefOrg = `brands/${brandId}`;
              } else {
                hrefOrg = `causes/${causeId}`;
              }

              return (
                <SwiperSlide key={index}>
                  <article>
                    <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl border bg-white p-[0.5rem] m-1  shadow-md transition-shadow hover:shadow-lg">
                      <Link
                        // href={`campaign-details/${ids}`}
                        href={`campaign/${ids}`}
                        className="flex space-x-[0.625rem]"
                      >
                        <figure className="w-[74.5%] h-full">
                          <Image
                            src={campaignImageTemp}
                            alt="item 1"
                            className="rounded-[0.625rem] w-full h-full object-cover"
                            width={150}
                            height={240}
                            loading="lazy"
                          />
                        </figure>
                        <span className="flex w-1/3 flex-col space-y-[0.625rem]">
                          <Image
                            key={1}
                            width={68}
                            height={74}
                            src={campaignImageSmallTemp1}
                            alt="item 1"
                            className="h-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                          />
                          <Image
                            key={2}
                            width={68}
                            height={74}
                            src={campaignImageSmallTemp2}
                            alt="item 1"
                            className="h-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                          />
                          <Image
                            key={3}
                            width={68}
                            height={74}
                            src={campaignImageSmallTemp3}
                            alt="item 1"
                            className="h-full rounded-[0.625rem] object-cover"
                            loading="lazy"
                          />
                          {/* {nft.slice(0, 3).map((item2, index2) => {
                            return (
                              <Image
                                key={index2}
                                width={68}
                                height={74}
                                src={"/images/collections/collection_1_2.png"}
                                alt="item 1"
                                className="h-full rounded-[0.625rem] object-cover"
                                loading="lazy"
                              />
                            );
                          })} */}
                        </span>
                      </Link>

                      <Link
                        href={`https://impactdots.world/campaign/${ids}`}
                        target="_blank"
                        className="font-display hover:text-accent dark:hover:text-accent text-jacarta-700 mt-4 block text-base dark:text-white"
                      >
                        {CampName}
                      </Link>

                      <div className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                        <div className="flex flex-wrap items-center">
                          <span className="dark:text-jacarta-400 mr-1">
                            {" "}
                            by{" "}
                          </span>{" "}
                          <Link href={hrefOrg} className="ml-2 shrink-0">
                            <Image
                              width={20}
                              height={20}
                              src={organizationImage}
                              alt="owner"
                              className="h-5 w-5 rounded-full"
                            />
                          </Link>
                        </div>
                        <span className="dark:text-jacarta-300 text-sm">
                          {/* 10K Items */}
                        </span>
                      </div>
                      {/* <span className="dark:text-jacarta-300 text-sm">
                          10 Items
                        </span> */}
                    </div>
                  </article>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* <!-- Slider Navigation --> */}
          <div className="group swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full p-3 bg-white text-jacarta-700 text-xl sm:!-left-6 after:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 30 30.000001"
              version="1.0"
              style={{ fill: "#f6851b", preserveAspectRatio: "xMidYMid meet" }}
            >
              <defs>
                <clipPath id="b9091e86e2">
                  <path
                    d="M 2.652344 7.480469 L 29.503906 7.480469 L 29.503906 21.269531 L 2.652344 21.269531 Z M 2.652344 7.480469 "
                    clipRule="nonzero"
                  />
                </clipPath>
              </defs>
              <g clipPath="url(#b9091e86e2)">
                <path
                  fill="#f6851b"
                  d="M 9.261719 7.75 C 9.535156 7.75 9.785156 7.828125 10.007812 7.976562 C 10.234375 8.128906 10.402344 8.328125 10.503906 8.582031 C 10.609375 8.832031 10.632812 9.089844 10.582031 9.355469 C 10.527344 9.625 10.40625 9.851562 10.214844 10.046875 L 7.132812 13.125 L 28.074219 13.125 C 28.253906 13.125 28.425781 13.160156 28.589844 13.230469 C 28.753906 13.296875 28.898438 13.394531 29.027344 13.519531 C 29.152344 13.644531 29.25 13.792969 29.316406 13.957031 C 29.386719 14.121094 29.417969 14.292969 29.417969 14.46875 C 29.417969 14.648438 29.386719 14.820312 29.316406 14.984375 C 29.25 15.148438 29.152344 15.292969 29.027344 15.421875 C 28.898438 15.546875 28.753906 15.644531 28.589844 15.710938 C 28.425781 15.78125 28.253906 15.8125 28.074219 15.8125 L 7.132812 15.8125 L 10.214844 18.894531 C 10.339844 19.019531 10.4375 19.167969 10.507812 19.332031 C 10.574219 19.496094 10.609375 19.667969 10.609375 19.847656 C 10.613281 20.027344 10.578125 20.199219 10.507812 20.363281 C 10.441406 20.527344 10.34375 20.671875 10.21875 20.800781 C 10.089844 20.925781 9.945312 21.023438 9.78125 21.089844 C 9.613281 21.160156 9.441406 21.195312 9.265625 21.191406 C 9.085938 21.191406 8.914062 21.160156 8.75 21.089844 C 8.582031 21.019531 8.4375 20.921875 8.3125 20.796875 L 2.9375 15.421875 C 2.8125 15.292969 2.714844 15.148438 2.644531 14.984375 C 2.578125 14.820312 2.542969 14.648438 2.542969 14.46875 C 2.542969 14.292969 2.578125 14.121094 2.644531 13.957031 C 2.714844 13.792969 2.8125 13.644531 2.9375 13.519531 L 8.3125 8.144531 C 8.574219 7.882812 8.890625 7.75 9.261719 7.75 Z M 9.261719 7.75 "
                  fillOpacity="1"
                  fillRule="nonzero"
                />
              </g>
            </svg>
          </div>
          <div className="group swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 30 30.000001"
              version="1.0"
              style={{ fill: "#f6851b", preserveAspectRatio: "xMidYMid meet" }}
            >
              <defs>
                <clipPath id="ac3fd1b26d">
                  <path
                    d="M 4.546875 8.273438 L 29.226562 8.273438 L 29.226562 20.613281 L 4.546875 20.613281 Z M 4.546875 8.273438 "
                    clipRule="nonzero"
                  />
                </clipPath>
              </defs>
              <g clipPath="url(#ac3fd1b26d)">
                <path
                  fill="#f6851b"
                  d="M 23.230469 20.75 C 22.976562 20.75 22.75 20.679688 22.539062 20.539062 C 22.332031 20.402344 22.179688 20.214844 22.082031 19.984375 C 21.988281 19.75 21.964844 19.511719 22.011719 19.265625 C 22.0625 19.019531 22.175781 18.808594 22.351562 18.632812 L 25.195312 15.789062 L 5.867188 15.789062 C 5.703125 15.789062 5.546875 15.757812 5.394531 15.695312 C 5.242188 15.632812 5.109375 15.542969 4.992188 15.425781 C 4.875 15.308594 4.785156 15.175781 4.722656 15.023438 C 4.660156 14.871094 4.628906 14.714844 4.628906 14.546875 C 4.628906 14.382812 4.660156 14.226562 4.722656 14.074219 C 4.785156 13.921875 4.875 13.789062 4.992188 13.671875 C 5.109375 13.554688 5.242188 13.464844 5.394531 13.402344 C 5.546875 13.339844 5.703125 13.308594 5.867188 13.308594 L 25.195312 13.308594 L 22.351562 10.464844 C 22.234375 10.347656 22.144531 10.214844 22.082031 10.0625 C 22.015625 9.910156 21.984375 9.753906 21.984375 9.585938 C 21.984375 9.421875 22.015625 9.265625 22.078125 9.109375 C 22.140625 8.957031 22.230469 8.824219 22.347656 8.707031 C 22.464844 8.589844 22.601562 8.5 22.753906 8.4375 C 22.90625 8.375 23.0625 8.34375 23.226562 8.34375 C 23.394531 8.34375 23.550781 8.378906 23.703125 8.441406 C 23.855469 8.503906 23.988281 8.59375 24.105469 8.710938 L 29.066406 13.671875 C 29.183594 13.789062 29.273438 13.921875 29.335938 14.074219 C 29.398438 14.226562 29.429688 14.382812 29.429688 14.546875 C 29.429688 14.714844 29.398438 14.871094 29.335938 15.023438 C 29.273438 15.175781 29.183594 15.308594 29.066406 15.425781 L 24.105469 20.386719 C 23.863281 20.628906 23.570312 20.75 23.230469 20.75 Z M 23.230469 20.75 "
                  fillOpacity="1"
                  fillRule="nonzero"
                />
              </g>
            </svg>
          </div>{" "}
        </>
      ) : (
        <FeatureComLoading />
      )}
    </>
  );
};

export default Feature_collections_carousel;
