import publicApiCall from "../utils/axiosCall";

export const campaignSliderNft = () => {
  return publicApiCall.get("app/campaign-names");
};
// API Call for Campaign Detail Page
export const campaign_detail_page = (campaignId) => {
  // console.log("Campaign Id ApI ", campaignId);

  return publicApiCall.get(`app/campaign-detail/${campaignId}`);
};

export const featureBrands = () => {
  return publicApiCall.get(`app/active-brands?isFeatured=true`);
};

export const brand_detail_page = (brandId) => {
  return publicApiCall.get(`app/active-brand/${brandId}`);
};

export const cause_detail_page = (causeId) => {
  return publicApiCall.get(`app/active-cause/${causeId}`);
};
