import React, { useState } from 'react';
import Image from 'next/image';

const CustomImage = (props) => {
    const [hideImage, setHideImage] = useState(false);

    return (
        hideImage ? (
            <Image
                src="/images/404.png"
                width={props.width}
                height={props.height}
                alt={props.alt ?? 'alt'} // Default alt text for the hidden image
                placeholder={props.placeholder}
                blurDataURL={props.blurDataURL}
            />
        ) : (
            <Image
                {...props}
                alt={props.alt ?? 'alt'} // Default alt text for the visible image
                onError={() => {
                    setHideImage(true);
                }}
            />
        )
    );
};

export default CustomImage;
